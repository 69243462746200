:root {
    --primary: #22bb9f;
    --font-primary: "Inter", sans-serif;
    --font-body: "Open Sans", sans-serif;
}

body {
    font-family: var(--font-primary);
}

.navbar {
    position: fixed;
    top: 0%;
    width: 100%;
    z-index: 999;

    a {
        text-decoration: none;
    }
}

.nav-menu {
    a {
        text-decoration: none;
    }
}

.price-heading {
    text-align: center;
}

.main-slider {
    max-height: 90vh;
    overflow: clip;
    position: relative;

    .overlay {
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
        position: absolute;
        inset: 0;
        z-index: 0;
    }

    .sldrContentWrapper {
        /* position: relative; */
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.35);
    }

    .backTextStroke {
        position: absolute;
        inset: 0;
        font-size: 200px;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: rgba(255, 255, 255, 0.35);
        top: -100px;
        text-transform: uppercase;
        font-weight: 800;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        color: transparent;
        z-index: -1;

    }

    .slider-slide {
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: center center !important;
    }
}

.text-stroke {
    -webkit-text-stroke-width: 2px;
    color: transparent;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.35);
}

.titleWithStroke {
    &.theme-dark {
        color: #414a53;

        .category {
            color: #8a929a;
        }

        .backTextStroke {
            -webkit-text-stroke-color: rgba(65, 74, 83, 0.25);
        }
    }

    &.theme-white {
        color: white;

        .backTextStroke {
            -webkit-text-stroke-color: rgba(255, 255, 255, 0.15);
        }

    }

    &.text-center {
        .backTextStroke {
            margin: auto;
            left: 0;
            right: 0;
        }
    }

    .backTextStroke {
        position: absolute;
        top: -40%;
    }

}

.backTextStroke {
    font-size: 7.5rem;
    -webkit-text-stroke-width: 1px;
    color: transparent;
    font-family: var(--font-body);
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.35);

}

@media (max-width:600px) {
    .backTextStroke {
        font-size: 3.5rem;
    }
}

.servicesCard {
    z-index: 1;

    .title {
        position: relative;

        .backNum {
            position: absolute;
            font-size: 60px;
            font-weight: 700;
            margin-top: -3px;
            position: absolute;
            top: 50%;
            left: -30px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: -1;
        }

    }
}

.bg-grd-btm {
    background-image: linear-gradient(180deg, #F3F6F5 76%, #31373F 76%);
}

.numStorke {
    font-size: 100px;
    font-weight: 800;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--primary);
    color: #31373F;
    font-family: var(--font-body);
}

.aboutHomeSection {
    background: #31373F;
}

@media screen and (min-width:1024px) {

    .aboutHomeSection {
        background-image: linear-gradient(180deg, #31373F 90%, #F3F6F5 90%);

    }
}

.timeline_date_part {
    position: relative;
    border-bottom: 2px solid #dbdce0;
    padding-bottom: 62px;

    &:after {
        content: "";
        display: block;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: url('image/dots.png') center center no-repeat;
        background-size: contain;
        position: absolute;
        left: 0;
        bottom: -17px;
    }

    .timeline_date {
        font-size: 60px;
        line-height: 1;
        letter-spacing: -.050em;
        font-weight: 700;
        color: #005bac;
        text-shadow: 4px 0 #dbdce0;

        span {
            display: inline-block;
            margin-left: 11px;
            font-size: 30px;
            letter-spacing: -.050em;
            text-shadow: none;
        }
    }
}

.steps_timeline_info_part {
    padding: 70px 45px 0 0;
}

.steps_timeline_info_part_wrapper {
    position: relative;
    background: #fff;
    padding: 37px 40px 31px;
    position: relative;

    &::before {
        content: '';
        width: 38px;
        height: 38px;
        background: #fff;
        margin-top: -38px;
        -webkit-clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%, 0 0);
        position: absolute;
        top: 0;
        left: 0;
    }
}


/* .step_grid{
    display: grid;
    grid-template-areas:'space' 'steps_item' ;
    grid-template-columns: 375px 1fr;
} */

.space {
    grid-area: space;
}

.steps_slider {
    /* max-width: 1527px; */
    width: 100%;
}

.steps_timeline_item {
    grid-area: steps_item;
}



.bg-contactHome {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-image: url('./image/BgContactBack.jpg');

    /* background-image: linear-gradient(180deg, transparent 76%, red 76%); */

    &::after {
        /* background-color: #F3F6F5; */
        width: 100%;
        height: 150px;
        position: absolute;
        content: '';
        bottom: 0;
        z-index: 0;

    }

    @media (max-width:1024px) {
        &::after {
            display: none;

        }
    }

    &>* {
        z-index: 2;
        position: relative;
    }

    .icon {
        width: 60px;
        color: #FFF;

        svg {
            fill: #FFF;
        }
    }
}


.testimonial-item {
    .author_container {
        padding-left: 200px;
    }

    .author {
        padding-left: 76px;
        position: relative;

        &::before {
            content: "ʻʻ";
            font-family: Arial, sans-serif;
            font-size: 167px;
            line-height: 140px;
            letter-spacing: -14px;
            font-weight: 700;
            position: absolute;
            top: -12px;
            left: -11px;
        }
    }
}

.animeslide-slide .container {
    position: relative;
}

.animeslide-slide.swiper-slide-active [data-animate] {
    opacity: 1;
    transform: none;
}

.animeslide-slide.swiper-slide-active .animeslide-06s {
    transition-delay: 0.6s;
}

.animeslide-slide.swiper-slide-active .animeslide-1s {
    transition-delay: 1s;
}

.animeslide-slide.swiper-slide-active .animeslide-1-5s {
    transition-delay: 1.5s;
}

.animeslide-slide.swiper-slide-active .animeslide-2s {
    transition-delay: 2s;
}

[data-animate] {
    opacity: 0;
    transition: all 0.8s ease-out;
}

[data-animate="bottom"] {
    transform: translate3d(0, 15px, 0);
}

.bg {
    clip-path: polygon(90% 0, 100% 9%, 100% 100%, 0 100%, 0 0);
    padding: 41px 40px 47px 40px;
}

.icon {
    font-size: 45px;
    color: #003D90;
}

.delivery_main_part {
    text-align: center;
}


.blog-img {
    height: 250px;
    width: 600px;
    object-fit: cover;
    background-position: top center;
    border-radius: 3px;
}




/* Features_2 */

.bgcolor {
    background: url("./image/asset 28.png");
    background-repeat: no-repeat;
}

.bg_img {
    background: url("./image/asset 32.png");
    background-repeat: no-repeat;
    background-position: center right;
}

.footer_bg {
    clip-path: polygon(90% 0, 100% 13%, 100% 100%, 0 100%, 0 0);
}

.last_p {
    text-align: center;
}

.content_img {
    background: url("./image/calcukator_bg_new.jpg");
    background-repeat: no-repeat;
    background-position: center right;
    width: 100%;

    /* position: relative; */
}

.bg-img-contact {
    background: url("./image/page_bg_2.jpg");
    background-repeat: no-repeat;
    min-height: 600px;
    background-position: center !important;
    background-size: cover !important;
}

.map-bg {
    background: url('./image/contacts-map.png');
    background-position: 50% 24%;
    background-repeat: no-repeat;
    opacity: 1;
}

.achievement-bg {
    background: url('./image/map_achievements_2-2.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.service_bg {
    background: url('./image/page_bg_1.jpg');
    height: 650px;
    background-color: #000;
    background-position: center !important;
    background-size: cover !important;

}

.Transporterium {
    transform: rotate(-90deg);
    font-size: 12px;
    font-weight: 700;
    /* text-align: right; */
    text-transform: uppercase;
    color: #f3f6f5;
    left: -20%;
}

.testi-bg {
    background: url('./image/testimonials-bg.png');
    background-position: 100% 44px;
    background-repeat: no-repeat;
    opacity: 1;
}

.true-border::before {
    content: "";
    width: 13px;
    height: 8px;
    display: inline-block;
    border-left: 2px solid #4EAE98;
    border-bottom: 2px solid #4EAE98;
    transform: rotate(-45deg);
    margin-right: 18px;
    /* top: -14px; */
}

.bg-back {
    background-image: url('./image/asset 30.png');
    background-position: top left;
    background-size: cover;
    bottom: 0;
    right: 0;
}

.about-bg {
    background-image: url('./image/page_bg_7.jpg');
    background-position: center !important;
    background-repeat: no-repeat;
    height: 800px;
}

.bg-img-about {
    background-image: url('./image/badge-img.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    clip-path: polygon(92.5% 0, 100% 16.5%, 100% 100%, 0 100%, 0 0);
}

.price-part-main {
    clip-path: polygon(90% 0, 100% 6%, 100% 100%, 0 100%, 0 0);
}
.team-content{
     clip-path: polygon(90% 0, 100% 20%, 100% 100%, 0 100%, 0 0);
}

.slider-bg::before {
    content: 'ʻʻ';
    font-family: Arial, sans-serif;
    font-size: 167px;
    line-height: 140px;
    letter-spacing: -14px;
    font-weight: 700;
    position: absolute;
    top: -20px;
    left: -70px;
    color: #4EAE98;
}

.about-feature>div {
    padding: 35px 30px 38px 45px
}

.play-btn {
    background: rgba(255, 255, 255, .2);
}

.bg-help {
    background-image: url('./image/testimonials-bg.png');
    background-position: top right;
    background-repeat: no-repeat;
}